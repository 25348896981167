{
  "name": "seazone",
  "version": "1.29.19",
  "scripts": {
    "ng": "ng ",
    "config": "ts-node ./scripts/config-env.ts",
    "start": "ng serve",
    "start:local": "node --max_old_space_size=4096 node_modules/@angular/cli/bin/ng serve",
    "build": "yarn run config && ng build --aot",
    "test": "ng test",
    "lint": "./node_modules/.bin/eslint 'src/**/*.{js,jsx,ts,tsx,html}' --quiet --fix",
    "format": "./node_modules/prettier/bin/prettier.cjs 'src/**/*.{js,jsx,ts,tsx,html,css,scss}' --write",
    "e2e": "ng e2e",
    "ssr": "rm -rf dist && yarn run build:universal && yarn run server",
    "ssr:dev": "rm -rf dist && yarn run build:universal:dev",
    "ssr:stage": "rm -rf dist && yarn run build:universal:stage",
    "ssr:prod": "rm -rf dist && yarn run build:universal:prod",
    "ssr:debug": "ng build --aot=true --output-hashing=all --named-chunks=false --build-optimizer=true  && ng run seazone:server && node dist/server/main.js",
    "ssr:cw": "ng build --aot=true --output-hashing=all --named-chunks=false --build-optimizer=true --watch",
    "ssr:sw": "ng run seazone:server --watch",
    "ssr:webpack": "webpack --watch",
    "ssr:server": "nodemon dist/server/main.js",
    "ssr:watch": "run-s ssr:universal:build:*",
    "ssr:universal:build:browser": "ng run seazone:build --watch",
    "ssr:universal:build:server": "node ./node_modules/npm-delay 15000 && ng run seazone:server --watch",
    "ssr:universal:build:nodemon": "node ./node_modules/npm-delay 35000 && nodemon --inspect dist/server/main.js",
    "build:server": "ng run seazone:server:production",
    "build:dev": "ng build --configuration dev",
    "build:stage": "ng build --configuration stage",
    "build:prod": "ng build --configuration production",
    "build:universal:dev": "yarn run build:dev && ng run seazone:server:dev",
    "build:universal:stage": "yarn run build:stage && ng run seazone:server:stage",
    "build:universal:prod": "yarn run build:prod && ng run seazone:server:production",
    "build:universal": "ng run seazone:build:production && ng run seazone:server:production",
    "build:local": "yarn run config && ng run seazone:build:production &&  node --max_old_space_size=4096 node_modules/@angular/cli/bin/ng run seazone:server:production",
    "server": "node dist/server/main.js",
    "analyze": "ng run seazone:build:production && esbuild-visualizer --metadata dist/browser/stats.json"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^17.3.3",
    "@angular/cdk": "^17.3.3",
    "@angular/cdk-experimental": "^17.3.3",
    "@angular/common": "^17.3.3",
    "@angular/compiler": "^17.3.3",
    "@angular/core": "^17.3.3",
    "@angular/forms": "^17.3.3",
    "@angular/material": "^17.3.3",
    "@angular/material-moment-adapter": "^17.3.3",
    "@angular/platform-browser": "^17.3.3",
    "@angular/platform-browser-dynamic": "^17.3.3",
    "@angular/platform-server": "^17.3.3",
    "@angular/router": "^17.3.3",
    "@angular/service-worker": "^17.3.3",
    "@googlemaps/js-api-loader": "^1.16.8",
    "@gorniv/ngx-universal": "^2.2.3",
    "@idapgroup/js-object-utils": "^1.1.0",
    "@ngneat/transloco": "^6.0.4",
    "@nguniversal/common": "^16.2.0",
    "@nguniversal/express-engine": "^16.2.0",
    "angular-draggable-droppable": "^8.0.0",
    "angular-resizable-element": "^7.0.2",
    "calendar-utils": "^0.10.4",
    "chart.js": "^2.9.3",
    "chartjs-plugin-datalabels": "^0.7.0",
    "class-transformer": "^0.5.1",
    "cookie-parser": "^1.4.6",
    "core-js": "^3.35.1",
    "cropperjs": "^1.6.1",
    "crypto-js": "^4.2.0",
    "date-fns": "^2.30.0",
    "domino": "https://github.com/angular/domino.git#9e7881d2ac1e5977cefbc557f935931ec23f6658",
    "eslint-plugin-node": "^11.1.0",
    "express": "^4.18.2",
    "file-saver": "^2.0.5",
    "geojson": "^0.5.0",
    "hammerjs": "^2.0.8",
    "i": "^0.3.7",
    "is_js": "^0.9.0",
    "js-confetti": "^0.11.0",
    "leaflet": "^1.9.4",
    "modules": "^0.4.0",
    "moment": "^2.29.4",
    "moment-timezone": "^0.5.45",
    "ng-qrcode": "^17.0.0",
    "positioning": "^2.0.1",
    "prettier-eslint": "^16.3.0",
    "reflect-metadata": "^0.2.1",
    "resize-observer-polyfill": "^1.5.1",
    "rxjs": "^7.8.0",
    "supercluster": "^8.0.1",
    "tslib": "^2.6.2",
    "zone.js": "~0.14.3"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^17.3.3",
    "@angular-eslint/builder": "^17.3.0",
    "@angular-eslint/eslint-plugin": "^17.3.0",
    "@angular-eslint/eslint-plugin-template": "^17.3.0",
    "@angular-eslint/schematics": "^17.3.0",
    "@angular-eslint/template-parser": "^17.3.0",
    "@angular/cli": "^17.3.3",
    "@angular/compiler-cli": "^17.3.3",
    "@angular/language-service": "^17.3.3",
    "@types/chart.js": "^2.9.19",
    "@types/compression": "^1.7.5",
    "@types/cookie-parser": "^1.4.6",
    "@types/crypto-js": "^4.1.1",
    "@types/dom-inputevent": "^1.0.6",
    "@types/file-saver": "^2.0.7",
    "@types/google.maps": "^3.55.12",
    "@types/jasmine": "~5.1.0",
    "@types/jasminewd2": "~2.0.3",
    "@types/jest": "^29.0.3",
    "@types/leaflet": "^1.9.0",
    "@types/mocha": "^9.1.1",
    "@types/node": "^20.11.5",
    "@types/resize-observer-browser": "^0.1.5",
    "@types/supercluster": "^7.1.0",
    "@typescript-eslint/eslint-plugin": "^5.38.0",
    "@typescript-eslint/parser": "^5.38.0",
    "dotenv": "^16.3.1",
    "esbuild-visualizer": "^0.6.0",
    "eslint": "^8.56.0",
    "eslint-config-prettier": "^9.0.0",
    "eslint-plugin-import": "^2.29.0",
    "eslint-plugin-import-newlines": "^1.3.4",
    "eslint-plugin-jsdoc": "^46.9.0",
    "eslint-plugin-prefer-arrow": "^1.2.3",
    "eslint-plugin-prettier": "^5.0.1",
    "eslint-plugin-unused-imports": "^3.0.0",
    "jasmine-core": "~5.1.0",
    "jasmine-spec-reporter": "~5.0.0",
    "karma": "~6.4.0",
    "karma-chrome-launcher": "~3.2.0",
    "karma-coverage": "~2.2.0",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "~2.1.0",
    "nodemon": "^3.0.1",
    "npm-delay": "^1.0.4",
    "npm-run-all": "^4.1.5",
    "prettier": "^3.1.0",
    "ts-node": "~10.9.1",
    "typescript": "~5.2.2"
  },
  "packageManager": "yarn@1.22.22+sha1.ac34549e6aa8e7ead463a7407e1c7390f61a6610"
}
